import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  content,
  cover,
  container,
  center,
  flex,
  grey,
  border,
  white,
  larger,
  thin,
  ext,
} from '../components/layout.module.css'

const Opportunity = () => {
  return (
    <Layout pageTitle="Opportunity">

      <div className={cover}>
        <StaticImage src="../images/opportunity/banner.jpg" alt="" style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            filter: "brightness(0.75)",
          }}
          loading="eager"
        layout="fullWidth"></StaticImage>
        <div className={container +' '+ content}>
          <h1>The Opportunity</h1>
        </div>
      </div>
      
      <h2>Flexible Services Program</h2>

      <div className={container}>
        <div className={flex}>
          <div>
            <h3>What is the Flexible Services Program?</h3>
            <p>In 2020, MassHealth (a combined agency of the Massachusetts Medicaid and Children’s Health Insurance Program) launched the Flexible Services Program (FSP), a three-year initiative to pilot evidence-based approaches that address a member’s social needs around food insecurity, housing instability, and homelessness. The program aims to improve health outcomes and reduce the need for costly medical care by building upon the expertise and assets of community-based social service organizations (SSOs).</p>
            <p>Through the FSP, Accountable Care Organizations (ACOs) can design individual nutrition and housing programs that best meet the needs of their members. ACOs are encouraged to partner with community-based social service organizations (SSO's) to provide services that make the most of existing community programs and resources.</p>
          </div>
          <div>
            <div className={grey +' '+ larger +' '+ ext}>
              <a href="https://www.mass.gov/topics/masshealth" rel="noreferrer noopener" target="_blank">
                What is MassHealth?
              </a>
              <a href="https://www.mass.gov/masshealth-demonstration-waiver" rel="noreferrer noopener" target="_blank">
                What is MassHealth’s Section 1115 Demonstration Waiver?
              </a>
              <a href="https://www.mass.gov/info-details/massachusetts-delivery-system-reform-incentive-payment-program#flexible-services-" rel="noreferrer noopener" target="_blank">
                What is the Flexible Services Program?
              </a>
            </div>
            <p><br/>Flexible Services eligibility is determined through a verification process based on overarching MassHealth criteria, as well as being part of the target population established by the ACO for their specific programs. To be eligible, an individual must be enrolled in a participating MassHealth ACO, meet an approved Health Needs-Based Criteria (e.g., complex physical health or behavioral health need), and demonstrate at least one of the three social risk factors (e.g., food insecurity, housing instability, or homelessness).</p>
          </div>
        </div>

        <p className={thin}><em><strong>Eligibility Note:</strong> The Flexible Services Program is not an entitlement program nor a covered service. Not all eligible members will receive Flexible Services. Participating ACOs define eligibility for their members using health needs criteria and risk factors.  </em></p>
      </div>

      <div className={grey}>
        <div className={container}>
          <h3 className={center}>The Flexible Services Program at C3</h3><br/>
          <p className={thin +' '+ center}>We partner with more than 20 social services organizations across Massachusetts through 14 distinct nutrition and housing programs to find solutions for our members’ complex health-related social needs and to invest in the communities that their health centers serve.</p><br/>
          <div className={flex}>
            <Link to="/sso-map" target="_blank">
              <StaticImage objectFit="contain" src='../images/sso-map.jpg' alt="Map of Massachusetts listing out social services organizations: Project Bread, Elder Services of Worcester Area, South Middlesex Opportunity Council, Making Opportunity Count, Eliot, Community Servings, Massachusetts Coalition for the Homeless, Project Hope, Father Bill's, Wayfinders, Community Action Pioneer Valley, Behavioral Health Network, The Food Bank of Western Massachusetts, and the Housing Assistance Corporation Cape Cod"></StaticImage>
            </Link>          
            <Link to="/healthcenter-map" target="_blank">
              <StaticImage objectFit="contain" src="../images/healthcenters-map.jpg" alt="Map of Massachusetts listing out healthcare centers: Hilltown Community Health Center, Community Health Center of Franklin County, Holyoke Health, Family Health Center of Worcester, Edward M. Kennedy Community Health Center, Lynn Community Health Center, North Shore Community Health, Charles River Community Health, Fenway Health, East Boston Neighborhood Health Center, New Health, Upham's Corner Health Center, The Dimock Center, Brockton Neighborhood Health Center, Community Health Connections, Springfield Health Services for the Homeless, Island Health Care, Kronos Health"></StaticImage>
            </Link>
          </div><br/>
        </div>
      </div>

      <div className={container}>
        <div className={center}>
          <StaticImage objectFit="contain" src="../images/opportunity/journey.png" alt="A member's journey to improved health and wellbeing through FLexible Services: member identification, connection to care team, referral to FLexible Services, meet member's goals."></StaticImage>
        </div>
        <div className={flex}>
          <div>
            <p>C3's approach creates conversations between our members, community health workers, and housing or nutrition specialists focused on meeting the member where they are and identifying all the ways in which the Flexible Services Program can support their goals. To reach a member’s goals could mean they begin receiving supports like medically tailored meal delivery, food vouchers, one-on-one nutrition counseling, or cooking equipment or begin working with a housing coordinator to identify immediate housing solutions or long-term safe and affordable housing.</p>
            <blockquote className={white+' '+border}>
              <p>I just think [the FSP] has this unbelievable capacity to meet people where they are and to carry them through as far as we can.<br/><strong>– Kathrine Dove, Clinical Coordinator, Eliot Community Human Services</strong></p>
            </blockquote>
          </div>
          <div>
            <p>Our members are at the center of our work, and through Flexible Services, we have built strong relationships between our health care and social services teams, ensuring that members have the support they need to be healthy.</p>
            <blockquote className={white+' '+border}>
              <p>The fact that C3 is so invested in their population has worked to the benefit of each and every member that we’ve had with them.<br/><strong>– Delilah Kiely, Flexible Services Program Director, Eliot Community Human Services</strong></p>
            </blockquote>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Opportunity